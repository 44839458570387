<template>
  <div id="app">
    <router-view></router-view>
<!--    <div class="container-fluid main-header">
      <header>
        <div class="pt-5">
          <div class="d-flex justify-content-between">
            <a href="/">
              <img src="./assets/img/ardi-logo-wh.svg" class="logo" width="200" />
            </a>
            <div class="my-auto  phone">
                        <span class="align-top">
                            <img src="./assets/img/phone.svg" width="20" />
                        </span>
              <span class="ml-2"><a href="tel:0322101010" class="text-decoration-none text-white ardi-phone">0322 10 10 10</a></span>
            </div>
          </div>
        </div>
      </header>
    </div>
    <div class="container">
      <div class="row justify-content-center m-0">
        <div class="col-11 p-0 content-background ">
          <form-wizard shape="cyrcle"
                       color=""
                       errorColor="#b75f67"
                       class="">
            <tab-content title="" icon="driver" class="p-md-5 p-sm-0 " :before-change="()=>validateStep('step1')">
              <step1 ref="step1" @on-validate="mergePartialModels"/>
            </tab-content>
            <tab-content title="" icon="accidentPlace" class="p-md-5 p-sm-0 " :before-change="()=>validateStep('step2')">
              <step2 ref="step2" @on-validate="mergePartialModels"/>
            </tab-content>

            <tab-content title="" icon="tpl" class="p-md-5 p-sm-0 " :before-change="()=>validateStep('step3')">
              <step3 ref="step3" :isTPLActive="finalModel.isTPLActive" :isMAActive="finalModel.isMAActive"
                     @on-validate="mergePartialModels"/>
            </tab-content>

            <tab-content title="" icon="payment" class="p-md-5 p-sm-0 " :before-change="()=>validateStep('step4')">
              <step4 ref="step4" @on-validate="mergePartialModels"/>
            </tab-content>

            <tab-content title="" class="p-md-5 p-sm-0 " icon="infoPreview">
              <Rate v-show="rateManager" :rate="rate"> </Rate>
              <preview :fullName="finalModel.fullName"
                       :DateOfBirth="finalModel.DateOfBirth"
                       :IdNumber="finalModel.IdNumber"
                       :PhoneNumber="finalModel.PhoneNumber"
                       :ActualAddress="finalModel.ActualAddress"
                       :email="finalModel.email"
                       :description="finalModel.description"
                       :tpl="finalModel.tpl"
                       :ma="finalModel.ma"
                       :accidentPlace="finalModel.accidentPlace"
                       :accidentDate="finalModel.accidentDate"
                       :agreementText="finalModel.agreementText"
                       :IBAN="finalModel.IBAN"
                       :IBANOwner="finalModel.IBANOwner"
                       :agreement="finalModel.agreement"
                       :amount="finalModel.amountOfDamage"
                       :isInformed="finalModel.informed"
                       v-show="!rateManager"/>
              &lt;!&ndash;<pre>{{finalModel}}</pre>&ndash;&gt;


            </tab-content>

            <button class="btn btn-ardi-green-back nav-btn prev-button pl-4 pt-2 pb-2 pr-4 m-2"
                    slot="prev"
                    v-show="!rateManager">
              <img src="./assets/img/green-arrow.svg" />
            </button>
            <button class="btn btn-ardi-green pl-4 nav-btn next-button pr-4 pt-2 pb-2 m-2"
                    slot="next"
                    v-show="!rateManager">
              <img src="./assets/img/white-arrow.svg" />
            </button>
            <button class="btn btn-ardi-green  nav-btn finish-button  pt-2 pb-2 m-2"
                    @click="sendData"
                    slot="finish"
                    v-show="!rateManager">
              <div v-show="!spinner">დადასტურება</div>
              <div v-show="spinner" class="lds-css ng-scope pl-4 pr-4">
                <div class="lds-spinner" style="width:100%;height:100%"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
              </div>
            </button>
            <div class="d-flex justify-content-center">
              <button class="btn btn-ardi-green  nav-btn rate-finish-button  pt-2 pb-2 m-2"
                      @click="SendRateEmail"
                      :disabled="emailResponse"
                      v-show="rateManager">
                დასრულება
              </button>
            </div>

          </form-wizard>
        </div>
      </div>
    </div>
    <div>
      <footer>
        <div class="container-fluid main-header footer-content">
          <div class="d-flex justify-content-between">
            <div class="social">
              <span class="m-1"><a href="https://www.facebook.com/ardiinsurance/"><img src="./assets/img/facebook.svg" /></a></span>
              <span class="m-1"><a href="https://www.instagram.com/ardiinsurance/"><img src="./assets/img/insta.svg" /></a></span>
            </div>
            <p class="mt-4 copyright-text small ">&copy; ყველა უფლება დაცულია</p>
          </div>
        </div>

      </footer>
    </div>-->
  </div>
</template>

<script>


export default {
  name: 'App',
  /*components: {
    FormWizard,
    TabContent,
   /!* WizardButton,*!/
    step1,
    step2,
    step3,
    step4,
    preview,
    Rate
  },
  data () {
    return {
      finalModel: {},
      rateManager: false,
      spinner: false,
      emailResponse:false,
      rate: {
        comment: '',
        rating: null
      }
    }
  },
  mounted: function () {
    console.log(this.$route.query.id);
    axios.get('/Home/UrlValidation/'+this.$route.query.id)
      .then(function (response) {
        console.log(response);
        if (response.data === false) {
          alert("ლინკი არ არის ვალიდური, განაცხადი უკვე შევსებულია");
          location.replace('http://ardi.ge');
        }


      })
      .catch(function (error) {
        //alert("ERROR:  დაფიქსირდა შეცდომა" + (error.message | error));
        console.log(error);
      });
  },
  methods: {
    validateStep(name) {
      let refToValidate = this.$refs[name];
      return refToValidate.validate();
    },
    sendData() {
      console.log(this.finalModel);
      this.spinner = !this.spinner;
      let that = this;
      axios.post('/Home/Process', {
        OID: that.finalModel.oid,
        DriverFullName: that.finalModel.fullName,
        DriverIDNumber: that.finalModel.IdNumber,
        DriverActualAddress: that.finalModel.ActualAddress,
        DriverPhone: that.finalModel.PhoneNumber,
        DriverEmail: that.finalModel.email,
        DriverBirthDay: that.finalModel.DateOfBirth,
        Amount: that.finalModel.amountOfDamage,
        AccidentDate: that.finalModel.accidentDate,
        Description: that.finalModel.description,
        AccidentPlace: that.finalModel.accidentPlace,
        TPL: that.finalModel.tpl,
        MA: that.finalModel.ma,
        Agreement: that.finalModel.agreement,
        BankAccountNumber: that.finalModel.IBAN,
        BankAccountOwner: that.finalModel.IBANOwner,
        CarStateNumber: that.finalModel.car.Number,
        FullName:that.finalModel.insurer.name[0] + ' '+that.finalModel.insurer.name[1],
      })
        .then(function (response) {
          console.log(response);
          that.rateManager = !that.rateManager;

        })
        .catch(function (error) {
          alert("ERROR:  დაფიქსირდა შეცდომა" + (error.message | error));
          console.log(error);
        });
    },
    SendRateEmail() {
      let that = this;
      this.emailResponse=true;
      axios.post('/Home/SendManagerRate', {
        OID: that.$route.query.id,
        HEART: that.rate.rating,
        COMMENT: that.rate.comment
      })
        .then(function (response) {
          that.emailResponse=true;
          if (response.data === true) {
            location.replace('https://ardi.ge');
          }

        })
        .catch(function (error) {
          alert("ERROR:  დაფიქსირდა შეცდომა" + (error.message | error));
          console.log(error);
        });


      //location.replace('http://ardi.ge');
    },
    onUpdateProp(newValue) {
      this.rate = newValue;
    },
    mergePartialModels(model, isValid) {
      if (isValid) {
        // merging each step model into the final model
        this.finalModel = Object.assign({}, this.finalModel, model);
        console.log(this.finalModel);
      }
    }
  }*/
}
</script>

<style>
/*
.main{
  padding-bottom:11rem !important;
}
.content-background{
  background-color: #fafafa !important;
  border-radius: 10px !important;
  !*padding: 30px;*!
  box-shadow: 0 10px 10px 10px rgba(210, 210, 216, 0.35) !important;
  height:729px !important;
}
.btn-ardi-green {
  background-color: #007960 !important;
  color: #fff !important;
  box-shadow: 0 10px 10px 0 #30A1352E !important;
}
.btn-ardi-green-back {
  background-color: #DDEFDE !important;
  color: #fff !important;
}
.active{
  border-bottom:0.09rem solid #007960 !important;
}
.nav-btn{
  position: absolute !important;
  bottom:30px !important;
}
.prev-button{
  left:40% !important;

}
.next-button{
  right:40% !important;
}
.finish-button{
  right: 36% !important;
}
.rate-finish-button{
  right:44% !important;
}
.vue-form-wizard .wizard-nav-pills a {
  align-items:normal !important;
}

.vue-form-wizard .wizard-icon-circle.tab_shape{
  !*background-color:#43a646;*!
}
.vue-form-wizard .wizard-icon-circle{
  border-radius:0 !important;
  width:100% !important;
  background-color:#DDEFDE !important;
  height: 40px !important;
  border:0 !important;
}
.vue-form-wizard .wizard-navigation .wizard-progress-with-circle{
  height:0 !important;
  border-right: 0.06rem solid #fafafa !important;
}

!*responsive*!
@media (max-width: 500px) {
  .content-background{
    height:auto !important;
  }
  .nav-btn{
    position:unset !important;
  }

}
!*end responsive*!

!*.vue-form-wizard .wizard-navigation .wizard-progress-with-circle:last-child{
  border-right
}*!
.vue-form-wizard .wizard-nav-pills>li>a{
  top:0 !important;
}
#step-0 {
  border-top-left-radius: 10px !important;
  border-right: 0.06rem solid #fafafa !important;
}
#step-1 {

  border-right: 0.06rem solid #fafafa !important;
}
#step-2 {

  border-right: 0.06rem solid #fafafa !important;
}
#step-3 {

  border-right: 0.06rem solid #fafafa !important;
}
#step-4 {

  border-right: 0.06rem solid #fafafa !important;
}
#step-5 {

  border-right: 0.06rem solid #fafafa !important;
}
#step-6 {

  border-right: 0.06rem solid #fafafa !important;
}
#step-7 {

  border-right: 0.06rem solid #fafafa !important;
}

#step-8{
  border-top-right-radius: 10px !important;

}

.vue-form-wizard .wizard-icon-circle .wizard-icon-container{
  border-radius:0 !important;
}
.checked{
  background-color:#007960 !important;
}


!*loading spinner*!
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.lds-spinner {
  position: relative !important;
}
.lds-spinner div {
  left: 93px;
  top: 40px;
  position: absolute;
  -webkit-animation: lds-spinner linear 1s infinite;
  animation: lds-spinner linear 1s infinite;
  background: #b5b5b5;
  width: 14px;
  height: 32px;
  border-radius: 32%;
  -webkit-transform-origin: 7px 60px;
  transform-origin: 7px 60px;
}
.lds-spinner div:nth-child(1) {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-animation-delay: -0.916666666666667s;
  animation-delay: -0.916666666666667s;
}
.lds-spinner div:nth-child(2) {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
  -webkit-animation-delay: -0.833333333333333s;
  animation-delay: -0.833333333333333s;
}
.lds-spinner div:nth-child(3) {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
  -webkit-animation-delay: -0.75s;
  animation-delay: -0.75s;
}
.lds-spinner div:nth-child(4) {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-animation-delay: -0.666666666666667s;
  animation-delay: -0.666666666666667s;
}
.lds-spinner div:nth-child(5) {
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
  -webkit-animation-delay: -0.583333333333333s;
  animation-delay: -0.583333333333333s;
}
.lds-spinner div:nth-child(6) {
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(7) {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-animation-delay: -0.416666666666667s;
  animation-delay: -0.416666666666667s;
}
.lds-spinner div:nth-child(8) {
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg);
  -webkit-animation-delay: -0.333333333333333s;
  animation-delay: -0.333333333333333s;
}
.lds-spinner div:nth-child(9) {
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg);
  -webkit-animation-delay: -0.25s;
  animation-delay: -0.25s;
}
.lds-spinner div:nth-child(10) {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-animation-delay: -0.166666666666667s;
  animation-delay: -0.166666666666667s;
}
.lds-spinner div:nth-child(11) {
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg);
  -webkit-animation-delay: -0.083333333333333s;
  animation-delay: -0.083333333333333s;
}
.lds-spinner div:nth-child(12) {
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg);
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.lds-spinner {
  width: 51px !important;
  height: 23px !important;
  -webkit-transform: translate(-16px, -16px) scale(0.16) translate(16px, 16px);
  transform: translate(-16px, -16px) scale(0.16) translate(16px, 16px);
}
*/

/*end loading spinner*/
</style>
