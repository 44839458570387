import Vue from 'vue'
import VueRouter from 'vue-router'

const routes = [
  { name: 'home', path: '/:id?', component: ()=>import('./components/Home'), display: 'Home', icon: 'home' },
  { name: 'form', path: '/form/:id', component: ()=>import('./components/FormPage'), display: 'form', icon: 'home' },
  // otherwise redirect to home
  {path: '*', redirect: '/'}
  ]
Vue.use(VueRouter)

let router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({y: 0}),
  routes
})

export default router
